import React, { useState, useLayoutEffect, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from './App';
import Captcha from 'react-captcha-code';
import { Input, Button, message, Spin } from 'antd';
import { UserOutlined, SafetyOutlined, FieldTimeOutlined } from '@ant-design/icons';
import axios from 'axios';
import qs from 'qs';
import './Login.css'

export default function AiLoginer() {
    const {token, setToken, serverURL} = useContext(DataContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [checkCode, setCheckCode] = useState();
    const [captchaCode, setCaptchaCode] = useState();
    const [loading, setLoading] = useState(false);
    // 路径保护
    useLayoutEffect(() => {
        if(token){ navigate('/main'); };
    }, [token, navigate]);
    useEffect(() => {
        if(token){ navigate('/main'); };
    }, [token, navigate]);

    const handleClick = useCallback((captcha) => setCaptchaCode(captcha), []);

    const submit = e => {
        e.preventDefault();
        if (!username || username === '' || !password || password === '') {
            message.warning('帐号、密码不能为空');
            return false;
        } else if(!checkCode || (checkCode.toLowerCase() !== captchaCode)){
            message.error('验证码错误');
            // 更新验证码
            document.querySelector('.ai-check-code').click();
            return false;
        };

        setLoading(true);
        axios.post(serverURL + '/login.php', qs.stringify({
            username: username,
            password: password
        })).then((response) => {
            setLoading(false)
            setToken(true);
            localStorage.setItem('tokener', response.data.data);
            localStorage.setItem('iat', Date.now());
            localStorage.setItem('exp', 7200000);
        }).catch((error) => {
            setLoading(false)
            // 更新验证码
            document.querySelector('.ai-check-code').click();
            message.error(error.response.data.error.message);
        });
    };

    return (
        <div className="ai-login">
            <div className="ai-login-pager">
                <div className="ai-login-panel">
                    <h3><img src="/img/logo.png" /></h3>
                    <form onSubmit={submit}>
                        <Spin spinning={loading}>
                            <div className="ai-input-box"><Input value={username} onChange={e => setUsername(e.target.value)} size="large" placeholder="帐号" prefix={<UserOutlined />} /></div>
                            <div className="ai-input-box"><Input.Password value={password} onChange={e => setPassword(e.target.value)} size="large" placeholder="密码" prefix={<SafetyOutlined />} /></div>
                            <div className="ai-input-box"><Input value={checkCode} onChange={e => setCheckCode(e.target.value)} size="large" addonAfter={<Captcha className="ai-check-code" charNum={4} height={38} bgColor={'#fafafa'} onChange={handleClick} />} placeholder="验证码" prefix={<FieldTimeOutlined />} maxLength="4" /></div>
                            <Button htmlType="submit" size="large" type="primary" block>登录</Button>
                        </Spin>
                    </form>
                </div>
            </div>
        </div>
    );
};