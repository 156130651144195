import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from './App';
import { MainContext } from './Main';
import { Breadcrumb, Skeleton, List, Card, message, Popconfirm, Select, Spin, InputNumber } from 'antd';
import { HomeOutlined, UnorderedListOutlined, EditOutlined, CloseOutlined, ToTopOutlined, OrderedListOutlined, FileImageOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Item } = List;
const { Option } = Select;

function AiSelect({ defaultValue = '', newsId = '', handleUpdate = f => f}){
    return (
        <Select placeholder="排序" bordered={false} size="small" defaultValue={defaultValue} onChange={(value) => {handleUpdate(newsId, value)}}>
            <Option value="7">7</Option>
            <Option value="6">6</Option>
            <Option value="5">5</Option>
            <Option value="4">4</Option>
            <Option value="3">3</Option>
            <Option value="2">2</Option>
            <Option value="1">1</Option>
        </Select>
    );
};

function AiInput({ defaultValue = '', newsId = '', handleUpdate = f => f}){
    return (
        <InputNumber 
            min={1} 
            max={9999}  
            size="small" 
            defaultValue={defaultValue} 
            onChange={(value) => {handleUpdate(newsId, value)}} 
            data-default-value={defaultValue} 
            // parser={(value) => (/^\d+$/.test(value) ? value : 9999)} 
            style={{width: '75%'}}
         />
    );
};
// 新闻排序外部控制参数
let timeouter = null;
let keyer = '';
let valuer = '';

function AiCard({item = "", resetData = f => f}){
    const { token, serverURL, logout } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleBrowse = (key) => {
        navigate(`/main/newscontent/${key}`);
    };

    const handleEdit = (key) => {
        navigate(`/main/newsedit/${key}`);
    };

    const reqUpdateOrder = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: '/news_order.php',
            baseURL: serverURL,
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                id: keyer,
                order: valuer
            } 
        }).then((response) => {
            setLoading(false);
            message.success(response.data.data.message);
            resetData();
        }).catch((error) => {
            setLoading(false);
            message.error(error.response.data.error.message);
            if(error.response.data.code == 401){ logout(); };
        });
    };

    const handleUpdateOrder = (key, value) => {
        if(value){
            keyer = key;
            valuer = value;

            if(timeouter != null){
                clearTimeout(timeouter);
            };
            timeouter = setTimeout(reqUpdateOrder,500);
        };
    };

    const handleDelete = (key) => {
        setLoading(true);
        axios({
            method: 'post',
            url: '/news_delete.php',
            baseURL: serverURL,
            headers: {  
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                id: key
            }   
        }).then((response) => {
            setLoading(false);
            message.success(response.data.data.message);
            resetData();
        }).catch((error) => {
            setLoading(false);
            message.error(error.response.data.error.message);
            if(error.response.data.code == 401){ logout(); };
        });
    };

    return (
        <Spin spinning={loading}>
            <Card 
                cover={<img alt={item.text} src={item.img} />} 
                actions={[
                    <AiInput defaultValue={item.order} newsId={item.key} handleUpdate={handleUpdateOrder} />,
                    <FileImageOutlined key="totop" onClick={() => {handleBrowse(item.key)}} />,
                    <EditOutlined key="edit" onClick={() => {handleEdit(item.key)}} />,
                    <Popconfirm 
                        placement="topRight" 
                        title="您确定删除这条资讯吗？" 
                        onConfirm={() => {handleDelete(item.key)}} 
                        okText="确定删除"
                        cancelText="取消" 
                    ><CloseOutlined key="delete" style={{color: '#cf1322'}} /></Popconfirm>
                ]}
            >
                <Meta title={item.text} />
                <p style={{display: 'flex', margin: 0, paddingTop: '12px', color: '#999', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <span style={{display: 'block'}}>{item.time}</span>
                    <span style={{display: 'block'}}>[阅读：<em style={{color: 'rgb(24, 144, 255)'}}>{item.views}</em> 次]</span>
                </p>
            </Card>
        </Spin>
    );
};

export default function NewsLister(){
    const { token, serverURL, logout } = useContext(DataContext);
    const { boxHeight } = useContext(MainContext);
    const [newsData, setNewsData] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // 加载数据
    useEffect(() => {
        setLoading(true);
        reqData();
    }, []);

    const reqData = () => {
        axios({
            method: 'get',
            url: '/news_list_admin.php',
            baseURL: serverURL
        }).then((response) => {
            setLoading(false);
            setNewsData(response.data.data);
        }).catch((error) => {
            setLoading(false);
            message.error(error.response.data.error.message);
            if(error.response.data.code == 401){ logout(); };
        });
    };

    return (
        <div className="ai-wrapper">
            <Breadcrumb className="ai-breadcrumb">
                <Breadcrumb.Item><HomeOutlined /><span>地利官网</span></Breadcrumb.Item>
                <Breadcrumb.Item><UnorderedListOutlined /><span>资讯列表</span></Breadcrumb.Item>
            </Breadcrumb>
            <section className="ai-section" style={{height: boxHeight + 'px'}}>
                <Skeleton Active loading={loading}>
                    <List 
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 3,
                            xl: 3,
                            xxl: 4
                        }} 
                        dataSource={newsData} 
                        renderItem={item => (<Item><AiCard item={item} resetData={reqData} /></Item>)} 
                    />
                </Skeleton>
            </section>
        </div>
    );
};