import React, { useState, useContext } from 'react';
import axios from 'axios';
import { DataContext } from './App';
import { Breadcrumb, Form, Input, Button, message, Spin } from 'antd';
import { HomeOutlined, LockOutlined } from '@ant-design/icons';

export default function AdminEditer(){
    const { token, serverURL, logout } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);
        axios({
            method: 'post',
            url: '/password_update.php',
            baseURL: serverURL,
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                password: values.password
            }
        }).then((response) => {
            message.success(response.data.data.message);
            form.setFieldsValue({
                password: '',
                confirm: ''
            });
        }).catch((error) => {
            message.error(error.response.data.error.message);
            if(error.response.data.code == 401){
                logout();
            };
        }).then(() => {setLoading(false)});
    };

    return (
        <div className="ai-wrapper">
            <Breadcrumb className="ai-breadcrumb">
                <Breadcrumb.Item><HomeOutlined /><span>地利官网</span></Breadcrumb.Item>
                <Breadcrumb.Item><LockOutlined /><span>修改密码</span></Breadcrumb.Item>
            </Breadcrumb>
            <section className="ai-section" style={{ paddingTop: '10%', paddingRight: '24px'}}>
                <Spin spinning={loading}>
                    <Form 
                        name="adminEditer" 
                        form={form} 
                        labelCol={{ span: 8 }} 
                        wrapperCol={{ span: 6 }}
                        initialValues={{ remember: true }} 
                        onFinish={onFinish} 
                        autoComplete='off'
                    >
                        <Form.Item 
                            label="新密码" 
                            name="password" 
                            rules={[{ required: true, message: '请输入新密码' }]}
                        >
                            <Input.Password placeholder="请输入新密码" />
                        </Form.Item>
                        <Form.Item 
                            label="确认密码" 
                            name="confirm" 
                            dependencies={['password']} 
                            hasFeedback 
                            rules={[
                                {
                                    required: true,
                                    message: '请输入确认密码',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('新密码与确认密码不一致'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="请再次输入新密码" />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">确认修改</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </section>
        </div>
    );
};