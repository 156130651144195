import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from './App';
import { MainContext } from './Main';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumb, Skeleton, message } from 'antd';
import { HomeOutlined, UnorderedListOutlined, FileImageOutlined } from '@ant-design/icons';
import axios from 'axios';
import './NewsContenter.css';

let moment = require('moment');

export default function NewsContenter(){
    const { key } = useParams();
    const { serverURL, logout } = useContext(DataContext);
    const { boxHeight } = useContext(MainContext);
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState();
    useEffect(() => {
        axios.get(serverURL + '/news_content_admin.php?id=' + key).then((response) => {
            setLoading(false);
            setNews(response.data.data);
        }).catch((error) => {
            setLoading(false);
            // console.log(error);
            message.error(error.response.data.error.message);
            if(error.response.data.code == 401){ logout(); };
        });
    }, [key]);
    
    return (
        <div className="ai-wrapper">
            <Breadcrumb className="ai-breadcrumb">
                <Breadcrumb.Item><HomeOutlined /><span>地利官网</span></Breadcrumb.Item>
                <Breadcrumb.Item><UnorderedListOutlined /><Link to="/main">资讯列表</Link></Breadcrumb.Item>
                <Breadcrumb.Item><FileImageOutlined /><span>资讯详情</span></Breadcrumb.Item>
            </Breadcrumb>
            <section className="ai-section" style={{height: boxHeight + 'px'}}>
                <Skeleton active loading={loading}>
                    <article className="ai-news-area">
                        <h1>{news && news.text}</h1>
                        <div className='date'>时间：{news && moment(news.time).format('YYYY年MM月DD日')}</div>
                        <div className='ai-news-content'>
                            <div dangerouslySetInnerHTML={news && {__html: news.content}}></div>
                        </div>
                    </article>
                </Skeleton>
            </section>
        </div>
    );
};