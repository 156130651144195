import React, { useState, useContext } from 'react';
import { DataContext } from './App';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export default function Avatar({ urlValue=false, handleUpdateFace = f => f }) {
    const { token, serverURL, logout } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(urlValue);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleChange = info => {
        
        console.log(info);
        if (info.file.status === 'error') {
            setLoading(false);
            if(info.file.error.status == 401){
                message.error(info.file.response.error.message);
                logout();
            };
        }
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            setImageUrl(info.file.response.data.src);
            handleUpdateFace(info.file.response.data.src);
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传JPG/PNG图片文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片文件不能大于2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    return (
        <Upload
            name="imager"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={serverURL + "/upload_face.php"}
            beforeUpload={beforeUpload}
            onChange={handleChange} 
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
    );
};