import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from './App';
import { MainContext } from './Main';
import { Breadcrumb, Form, Input, message, DatePicker, Select, Button, Spin, InputNumber } from 'antd';
import { HomeOutlined, FormOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import Avatar from './Avatar2';
import AiEditor from './AiEditor';

const { Option, OptGroup } = Select;

export default function NewsAdder(){
    const { token, serverURL, logout } = useContext(DataContext);
    const { boxHeight } = useContext(MainContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log(values.content);
        setLoading(true);
        axios({
            method: 'post',
            url: '/news_insert.php',
            baseURL: serverURL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            data: {
                title: values.title,
                face: values.face,
                time: values.time,
                order: values.order,
                content: values.content.replace(/"/g, "'")
            }
        }).then((response) => {
            setLoading(false);
            message.success(response.data.data.message);
            navigate('/main');
        }).catch((error) => {
            setLoading(false);
            message.error(error.response.data.error.message);
            if(error.response.data.code == 401){
                logout();
            };
        });
    };

    const formIemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
            md: { span: 6 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
            md: { span: 12 }
        }
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 18,
                offset: 8
            },
            md: {
                span: 12,
                offset: 6
            }
        }
    };

    const updateFace = (url) => {
        form.setFieldsValue({
            face: url
        });
    };
    const updateContent = content => {
        form.setFieldsValue({
            content: content
        });
    }

    return (
        <div className="ai-wrapper">
            <Breadcrumb className="ai-breadcrumb">
                <Breadcrumb.Item><HomeOutlined /><span>地利官网</span></Breadcrumb.Item>
                <Breadcrumb.Item><FormOutlined /><span>添加资讯</span></Breadcrumb.Item>
            </Breadcrumb>
            <section className="ai-section ai-newsEditor" style={{height: boxHeight + 'px'}}>
                <Spin spinning={loading}>
                    <Form 
                        {...formIemLayout} 
                        form={form} 
                        name="newsAdd" 
                        onFinish={onFinish} 
                    >
                        <Form.Item 
                            name="title" 
                            label="资讯标题" 
                            rules={[{
                                required: true,
                                message: "请输入资讯标题"
                            }]}
                        >
                            <Input placeholder="请输入资讯标题" />
                        </Form.Item>
                        <Form.Item
                            name="time" 
                            label="发布日期"
                            rules={[{
                                required: true,
                                message: "请选择发布日期"
                            }]} 
                        >
                            <DatePicker locale={locale} />
                        </Form.Item>
                        <Form.Item
                            name="face" 
                            label="资讯封面"
                            rules={[{
                                required: true,
                                message: "请选择资讯封面"
                            }]}
                        >
                            <Avatar handleUpdateFace={updateFace} />
                        </Form.Item>
                        <Form.Item
                            name="order" 
                            label="资讯排序"
                            rules={[{
                                required: true,
                                message: "请填写排序"
                            }]}
                        >
                            {/* <Select placeholder="请选择排序">
                                <OptGroup label="1-4 首页显示">
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                </OptGroup>
                                <OptGroup label="1-6 资讯页显示">
                                    <Option value="5">5</Option>
                                    <Option value="6">6</Option>
                                </OptGroup>
                                <OptGroup label="默认时间倒序显示">
                                    <Option value="7">7</Option>
                                </OptGroup>
                            </Select> */}
                            <InputNumber 
                                min={1} 
                                max={9999} 
                            />
                        </Form.Item>
                        <Form.Item
                            name="content" 
                            label="资讯内容"
                            rules={[{
                                required: true,
                                message: "请输入资讯内容"
                            }]}
                        >
                            <AiEditor handleUpdateContent={updateContent} />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">发布资讯</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </section>
        </div>
    );
};