import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from './App';
import axios from 'axios';
import E from 'wangeditor';
import { message } from 'antd';
import './AiEditor.css';

export default function AiEditor({ contentValue = '', handleUpdateContent = f => f }) {
    const { token, serverURL, logout } = useContext(DataContext);
    const [content, setContent] = useState(contentValue);
    let editor = null;

    useEffect(() => {
        editor = new E('#editorArea');

        editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 上传图片大小2M
        editor.config.uploadImgServer = serverURL + '/imager'; // 路径
        editor.config.uploadImgMaxLength = 1; // 限制一次最多上传 1 张图片
        editor.config.height = 500; // 设置编辑区域高度为 500px
        editor.config.pasteFilterStyle = true; // 开启粘贴样式的过滤
        // 配置触发 onchange 的时间频率，默认为 200ms
        editor.config.onchangeTimeout = 500; // 修改为 500ms
        // 图片上传配置
        editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png'];
        editor.config.customUploadImg = function (files, insert) {
            // files 是 input 中选中的文件列表
            console.log(files);
            if (files[0]) {
                const formData = new window.FormData();
                formData.append('imager', files[0]);
                axios({
                    method: 'post',
                    url: '/upload_image.php',
                    baseURL: serverURL,
                    data: formData
                }).then((response) => {
                    // message.success(response.data);
                    // console.log(response.data.data.src);
                    const data = response.data;
                    message.success(data.data.message);
                    if (data) {
                        insert(data.data.src);
                    } else {
                        console.log(data.error.message);
                    }
                }).catch((error) => {
                    message.error(error.response.data.error.message);
                    if (error.response.data.code == 401) {
                        logout();
                    };
                });
            } else {
                message.info('请选择要上传的图片');
            };
        };
        editor.config.menus = [
            'head', // 标题
            'bold', // 粗体
            // 'fontSize', // 字号
            // 'fontName', // 字体
            'italic', // 斜体
            'underline', // 下划线
            'strikeThrough', // 删除线
            // 'foreColor', // 文字颜色
            // 'backColor', // 背景颜色
            'link', // 插入链接
            // 'list', // 列表
            'justify', // 对齐方式
            // 'quote', // 引用
            // 'emoticon', // 表情
            'image', // 插入图片
            // 'table', // 表格
            // 'video', // 插入视频
            // 'code', // 插入代码
            'undo', // 撤销
            'redo' // 重复
        ];
        editor.config.lang = {
            设置标题: 'Title',
            字号: 'Size',
            文字颜色: 'Color',
            设置列表: 'List',
            有序列表: 'Ordered List',
            无序列表: 'Unordered List',
            对齐方式: 'Align',
            靠左: 'Left',
            居中: 'Center',
            靠右: 'Right',
            正文: 'p',
            链接文字: 'link text',
            链接: 'link',
            上传图片: 'Upload',
            网络图片: 'Web',
            图片link: 'image url',
            插入视频: 'Video',
            格式如: 'format',
            上传: 'Upload',
            创建: 'init'
        };
        // onchange写入form新值
        editor.config.onchange = function (newHtml) {
            // console.log("change 之后最新的 html", newHtml);
            handleUpdateContent(newHtml);
        };
        /**一定要创建 */
        editor.create();

        return () => {
            // 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
            editor.destroy();
        };
    }, []);
    useEffect(() => {
        getHtml();
    }, [content]);

    function getHtml() {
        editor.txt.html(content);
    }

    return (
        <div>
            <div id="editorArea"></div>
        </div>
    );
};