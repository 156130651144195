import React, { useState, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import './App.css';

import Login from './Login';
import Main from './Main';
import NewsLister from './NewsLister';
import NewsContenter from './NewsContenter';
import NewsAdder from './NewsAdder';
import NewsEditer from './NewsEditer';
import AdminEditer from './AdminEditer';

export const DataContext = createContext();

function App() {
    let tokener = localStorage.getItem('tokener');
    if(tokener){
        const iat = localStorage.getItem('iat');
        const exp = localStorage.getItem('exp');
        if((Date.now() - iat) > exp){
            localStorage.removeItem('tokener');
            localStorage.removeItem('iat');
            localStorage.removeItem('exp');
            tokener = false;
        };
    };
    const [token, setToken] = useState(tokener);
    // const serverURL = 'http://localhost/diliwebsite';
    // const serverURL = 'http://dili.qingstudy.net/diliwebsite';
    const serverURL = '/diliwebsite';

    // 监听Storage变动事件
    window.addEventListener('storage', (e)=>{
        setToken(localStorage.getItem('tokener'));
    });
    // 登出
    const logout = () =>{
        axios.get(serverURL + '/logout.php').then((response) => {
            message.success(response.data.data.message);
            localStorage.removeItem('tokener');
            localStorage.removeItem('iat');
            localStorage.removeItem('exp');
            setToken(false);
        }).catch((error) => {
            console.log(error);
            localStorage.removeItem('tokener');
            localStorage.removeItem('iat');
            localStorage.removeItem('exp');
            setToken(false);
        });
    };

    return (
        <DataContext.Provider value={{
            token,
            setToken,
            serverURL,
            logout
        }}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/main" element={<Main />}>
                    <Route path="" element={<NewsLister />} />
                    <Route path="adminedit" element={<AdminEditer />} />
                    <Route path="newsadd" element={<NewsAdder />} />
                    <Route path="newsedit/:key" element={<NewsEditer />} />
                    <Route path="newscontent/:key" element={<NewsContenter />} />
                </Route>
            </Routes>
        </DataContext.Provider>
    );
};

export default App;
