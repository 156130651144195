import React, { useState, useEffect, useLayoutEffect, useContext, createContext } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { DataContext } from './App';
import { Layout, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, FormOutlined, UnorderedListOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons';
import './Main.css';

const { Header, Sider, Content } = Layout;

export const MainContext = createContext();

export default function Main(props) {
    const {token, logout } = useContext(DataContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [boxHeight, setBoxHeight] = useState(window.outerHeight-240);

    // 路径保护
    useLayoutEffect(() => {
        if(!token){ navigate('/'); };
    }, [token, navigate]);
    useEffect(() => {
        if(!token){ navigate('/'); };
    }, [token, navigate]);

    const toggle = () => {
        setCollapsed(!collapsed);
    };
    // 监听窗口尺寸改变
    window.addEventListener('resize', ()=>{
        setBoxHeight(window.outerHeight-240);
    });
    
    return (
        <Layout className="ai-site">
            <Sider className="ai-site-sider" trigger={null} collapsible collapsed={collapsed}>
                <div className="logo"><img src="/img/logo.png" /></div>
                <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
                    <Menu.Item key="/main/newsadd" icon={<FormOutlined />}><Link to="/main/newsadd">添加资讯</Link></Menu.Item>
                    <Menu.Item key="/main" icon={<UnorderedListOutlined />}><Link to="/main">资讯列表</Link></Menu.Item>
                    <Menu.Item key="/main/adminedit" icon={<LockOutlined />}><Link to="/main/adminedit">修改密码</Link></Menu.Item>
                </Menu>
            </Sider>
            <Layout className="ai-site-layout">
                <Header className="ai-site-layout-header">
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, { className: 'trigger', onClick: toggle })}
                    <div>
                        <strong>欢迎回来：<span style={{ color: '#1890ff' }}>网站管理员</span></strong>
                        <PoweroffOutlined className="trigger" onClick={logout} />
                    </div>
                </Header>
                <Content className="ai-site-layout-content">
                    <MainContext.Provider value={{ boxHeight }}>
                        <Outlet />
                    </MainContext.Provider>
                </Content>
            </Layout>
        </Layout>
    );
};